export const openMenu = (el, tl, position) => {
  tl.to(el, {
    autoAlpha: 1,
    duration: 0.5,
    ease: 'power1.inOut'
  }, position);
};

export const closeMenu = (el, tl, position) => {
  tl.to(el, {
    autoAlpha: 0,
    duration: 0.5,
    ease: 'power1.inOut'
  }, position);
};

export const showItem = (el, tl, position) => {
  tl.fromTo(el, {
    y: 0,
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
  }, {
    y: '-3rem',
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
    duration: 0.3,
    ease: 'power1.inOut'
  }, position);
};

export const hideItem = (el, tl, position) => {
  tl.fromTo(el, {
    y: '3rem',
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)'
  }, {
    y: 0,
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    duration: 0.3,
    ease: 'power1.inOut'
  }, position);
};