import gsap from 'gsap';
import MoveTo from 'moveto';
import { closeMenu, hideItem, openMenu, showItem } from './functions';

const init = () => {
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const logo = document.querySelector('.header__logo');
  const links = document.querySelectorAll('.header__nav a');
  const header = document.querySelector('.header');
  const whatSection = document.querySelector('.what');
  const navBtn = document.querySelector('.header__btn-container button');

  const moveTo = new MoveTo(
      {
        easing: 'easeInCubic',
        duration: 800,
        tolerance: header.clientHeight
      },
      {
        easeInCubic(t, b, c, d) {
          t /= d;
          return c * t * t * t + b;
        }
      }
  );

  const tl = gsap.timeline();

  [...links].forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      const id = link.getAttribute('href');
      const target = document.querySelector(id);
      target && moveTo.move(target);

      if (isMobile) {
        closeMenu('.header__menu', tl);
        navBtn.classList.remove('is-active');
      }
    });
  });

  logo.addEventListener('click', () => {
    // e.preventDefault();
    moveTo.move(-window.scrollY);
  });

  navBtn.addEventListener('click', () => {
    navBtn.classList.toggle('is-active');
    header.classList.toggle('header_active');

    if (navBtn.classList.contains('is-active')) {
      tl.set('.header__logo', {
        className: 'header__logo header__logo_filter-none'
      });
      tl.set('.header__btn-container', {
        className: 'header__btn-container header__btn-container_filter-none'
      });

      openMenu('.header__menu', tl, '<');
      hideItem(['.header__nav a', '.header__social', '.header__lang'], tl, '-=0.25');
    } else {
      tl.set('.header__logo', {
        className: 'header__logo'
      });
      tl.set('.header__btn-container', {
        className: 'header__btn-container'
      });

      showItem(['.header__nav a', '.header__social', '.header__lang'], tl, '<');
      closeMenu('.header__menu', tl);
    }
  });

  window.addEventListener('load', () => {
    if (window.scrollY !== 0) {
      return;
    }

    const timeout = setTimeout(() => {
      whatSection && moveTo.move(whatSection);
    }, 3000);

    window.addEventListener('scroll', () => {
      clearTimeout(timeout);
    });
  });
};

export default {
  init
};
