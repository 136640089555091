import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { getSectionTimeline, setActiveSection, showTube, showSection, setParallax } from './functions';

const isMobile = window.matchMedia('(max-width: 768px)').matches;
const tubes = document.querySelectorAll('.content > .tube');
const defaultTubeScales = [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3];
const parallaxItems = document.querySelectorAll('[data-speed]');

const init = () => {
  gsap.registerPlugin(ScrollTrigger, window.ScrollSmoother);

  if (!isMobile) {
    const smoother = window.ScrollSmoother.create({
      wrapper: '.wrapper',
      content: '.content',
      smooth: 2,
      smoothTouch: 0.1,
      effects: true
    });

    window.addEventListener('accordion-change', ({ detail }) => {
      smoother.paused(detail.paused);
    });
  }

  if (isMobile) {
    parallaxItems.forEach((item) => {
      setParallax(item);
    });
  }

  const heroAnimation = () => {
    const headingTl = gsap.timeline();

    headingTl.fromTo(
      '.hero__caption-animation',
      {
        y: '4rem',
        opacity: 0
      },
      {
        y: 0,
        opacity: 1,
        duration: 1.8,
        ease: 'power2.out'
      }
    );

    headingTl.fromTo(
      '.hero__title-animation',
      {
        y: '6rem',
        opacity: 0
      },
      {
        y: 0,
        opacity: 1,
        duration: 1.8,
        ease: 'power2.out'
      },
      '<'
    );

    headingTl.fromTo(
      tubes,
      {
        opacity: 0
      },
      {
        opacity: 1,
        duration: 2,
        ease: 'power0.out',
        stagger: -0.1
      },
      '-=1'
    );

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.hero',
        scrub: 1.5,
        pin: true,
        start: 'top top',
        invalidateOnRefresh: true
      }
    });

    tl.to('.hero__caption', {
      y: '-5rem',
      opacity: 0,
      duration: 1,
      ease: 'power0.inOut'
    });

    tl.to(
      '.hero__title',
      {
        y: '-3rem',
        opacity: 0,
        duration: 1,
        ease: 'power0.inOut'
      },
      '<'
    );

    tl.to('.header', {
      autoAlpha: 1,
      duration: 1,
      ease: 'power0.inOut'
    });
  };

  const tubeAnimation = () => {
    tubes.forEach((tube, index) => {
      gsap.set(tube, {
        scrollTrigger: {
          trigger: tube,
          pin: true,
          start: 'bottom bottom',
          end: 'max',
          invalidateOnRefresh: true
        },
        scale: defaultTubeScales[index]
      });
    });
  };

  const whatAnimation = () => {
    const section = document.querySelector('.what');
    const tl = getSectionTimeline(section);

    setActiveSection(section, tl);
    showTube(0, tl);
    showSection(section, tl, '-=0.5');

    tl.to(
      '.buy-button',
      {
        x: 0,
        duration: 0.5,
        ease: 'power1.inOut'
      },
      '-=0.8'
    );
  };

  const planAnimation = () => {
    const section = document.querySelector('.plan-visit');
    const tl = getSectionTimeline(section);

    setActiveSection(section, tl);
    showTube(1, tl);
    showSection(section, tl, '-=0.5');
  };

  const faqAnimation = () => {
    const section = document.querySelector('.faq');
    const tl = getSectionTimeline(section);

    setActiveSection(section, tl);
    showTube(2, tl);
    showSection(section, tl, '-=0.5');
    showTube(3, tl, '<');

    tl.set(
      '.header',
      {
        className: 'header header_white'
      },
      '<'
    );
  };

  const contactAnimation = () => {
    const section = document.querySelector('.contact');
    const tl = getSectionTimeline(section);

    setActiveSection(section, tl);
    showTube(4, tl);
    showSection(section, tl, '-=0.5');
  };

  const initAnimations = () => {
    const matchMedia = gsap.matchMedia();
    matchMedia.add(
      {
        isMobile: '(max-width: 768px)',
        isDesktop: '(min-width: 769px)'
      },
      (context) => {
        const { isMobile } = context.conditions;
        heroAnimation(isMobile);
        !isMobile && tubeAnimation(isMobile);
        whatAnimation(isMobile);
        planAnimation(isMobile);
        faqAnimation(isMobile);
        contactAnimation(isMobile);
      }
    );
  };

  window.addEventListener('load', initAnimations);
};

export default {
  init
};
