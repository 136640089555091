const videoContainer = document.querySelector('.video-box');
const playBtn = document.querySelector('.video-frame__btn');
const frame = document.querySelector('.video-frame');
const body = document.querySelector('body');

const preventScroll = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const events = () => {
  const video = videoContainer.querySelector('video');
  const videoFrame = frame.querySelector('video');

  video.addEventListener('click', () => {
    video.pause();
    frame.classList.toggle('video-frame_active');
    body.addEventListener('wheel', preventScroll, { passive: false });
    body.addEventListener('touchmove', preventScroll, { passive: false });
  });

  videoFrame.addEventListener('click', () => {
    videoFrame.paused ? videoFrame.play() : videoFrame.pause();
    playBtn.classList.toggle('video-frame__btn_active');
  });

  frame.addEventListener('click', (e) => {
    if (e.target === videoFrame) return;
    frame.classList.toggle('video-frame_active');
    video.play();
    body.removeEventListener('wheel', preventScroll);
    body.removeEventListener('touchmove', preventScroll);
  });
};

const init = () => {
  if (videoContainer && frame) {
    events();
  }
};

export default {
  init
};