import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const containers = [...document.querySelectorAll('.accordion__content')];
const lines = [...document.querySelectorAll('.accordion__line')];

const emitChangeEvent = (detail) => {
  window.dispatchEvent(new CustomEvent('accordion-change', {
    detail: detail
  }));
};

const onStart = () => {
  emitChangeEvent({ paused: true });
};

const onComplete = () => {
  emitChangeEvent({ paused: false });
  ScrollTrigger.refresh();
};

const events = () => {
  if (containers.length) {
    lines.forEach((line, idx) => {
      const chevron = line.querySelector('.accordion__chevron');
      const tl = gsap.timeline();

      tl.set(containers[idx], { height: 0 });
      line.addEventListener('click', () => {
        tl.to(containers[idx], {
          height: containers[idx].classList.contains('accordion__content_active') ? 0 : 'auto',
          duration: 0.6,
          ease: 'power1.inOut',
          onStart,
          onComplete
        });

        chevron.classList.toggle('accordion__chevron_active');
        containers[idx].classList.toggle('accordion__content_active');
      });
    });
  }
};

const init = () => {
  const accordion = document.querySelector('.accordion');

  if (accordion) {
    events();
  }
};

export default {
  init
};