import Components from './components';
import * as Sentry from '@sentry/browser';
import './utils/tickethub';

import '../css/main.scss';

Sentry.init({
  dsn: 'https://700f6b2d4c8348b5b090f8b9d53326e9@sentry.yournext.agency/77'
});

if (Components.length) {
  Components.forEach((component) => component.init());
}