import Reframe from './reframe';
import Animations from './animations';
import Header from './header';
import Accordion from './accordion';
import VideoBox from './video-box';
import Map from './map';
import Slider from './slider';
import Cart from './cart';
import Tickethub from './tickethub';

export default [
  Tickethub,
  Reframe,
  Animations,
  Accordion,
  VideoBox,
  Map,
  Slider,
  Cart,
  Header
];
