import { ScrollTrigger } from 'gsap/ScrollTrigger';

const init = () => {
  const cartSection = document.querySelector('#buy-tickets');
  if (cartSection !== null) {
    cartSection.addEventListener('click', () => {
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 500);
    }, true);
  }
};

export default {
  init
};