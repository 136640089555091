import styles from './styles';

const init = () => {
  const initMap = () => {
    const mapElement = document.querySelector('.plan-visit__map');
    const position = {
      lat: mapElement?.dataset.lat ? parseFloat(mapElement.dataset.lat) : 55.673352,
      lng: mapElement?.dataset.lng ? parseFloat(mapElement.dataset.lng) : 12.5646046
    };

    const map = new window.google.maps.Map(mapElement, {
      zoom: mapElement?.dataset.zoom ? parseInt(mapElement.dataset.zoom, 10) : 16,
      center: position,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: false,
      // gestureHandling: 'none',
      // keyboardShortcuts: false,
      styles
    });

    new window.google.maps.Marker({ position, map });
  };

  window.initMap = initMap;
};

export default {
  init
};
