import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

const init = () => {
  new Swiper('.slider', {
    slidesPerView: 'auto',
    speed: 800,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });
};

export default {
  init
};
